.gg-header {
    .top-bar {
        padding-top: 20px;
        background-color: white;
        .top-bar-items2 {
            flex: 1;
            .navbar-toggler {
                position: static;
                font-size: 2.5rem;
                display: none;
            }
            .phonenumber {
                line-height: 30px;
                margin-left: 50px;
                i {
                    margin-right:10px;
                }
                @media (max-width:576px) {
                    display: none;
                }
            }
            .textcol {
                color: $gold_dark;
                padding-left: 50px;
            }
        }
        .navbar-brand {
            img {
                max-height: 5rem;
            }
        }       
        .top-bar-items {
            .nav-link {
                color: $gold_dark;
                text-transform: uppercase;
                letter-spacing: .5px;
                &:hover {
                    color: $gold_dark;
                    background-color: transparent;
                }
                &[aria-expanded="true"] {
                    background: #876032;
                    color: white;
                }
            }
            .control-basket .toggle-basket-preview {
                background-color: $grey;
                color:black;
                position: relative;
                .mr-2 {
                    position: absolute;
                    background: $blue;
                    display: block;
                    min-width: 19px;
                    height: 20px;
                    border-radius: 10px;
                    color: white;
                    text-align: center;
                    line-height: 21px;
                    font-weight: bold;
                    top: -10px;
                    right: -15px;
                }
                @media (max-width:1170px) {
                    margin-right:15px;
                }
            }
            .control-search {
                .nav-link {
                    position: relative;
                    top: -2px;
                }
                i {
                    font-size: 1.5rem;
                }
            }
        } 
    }

    .navbar {
        background: #fff;
        border-bottom: unset !important;
        .brand-wrapper {
            height:60px;
        }
        #searchControlsList {
            padding:0;
            a.nav-link {
                font-size: 2rem;
                &:not(.collapsed) {
                    display: none;
                }
            }
        }
        #searchBox {
            min-width: unset;
            min-height: unset;
            padding:0;
            columns: unset;
            overflow: visible;
            .container-max {
                padding:0;
            }
            &.collapse {
                left: unset;
            }
            &.collapse.show {
                display: block;
            }
            .search-input {
                background: $grey;
                width:100%;
            }
        }
        .main-navbar-collapsable {
            ul.mainmenu {
                justify-content: space-between;
                > li {
                    &:hover {
                        background-color: unset;
                    }
                    > a {
                        position: relative;
                        color: black;
                        text-transform: uppercase;
                        text-align: center;
                        padding: 0;
                        &::after {
                            display:block;
                            content: '';
                            border-bottom: 2px solid $gold_dark;
                            transform: scaleX(0);  
                            transition: transform 250ms ease-in-out;
                        }
                        &:hover::after {
                            transform: scaleX(1);
                        }
                        > div {
                            max-width:70px;
                            margin:0 auto;
                        }
                    }
                }
            }
        }
    }   

    .hidden-bar {
        .cmp-search-box {
            position: fixed;
            right: 0;
            left: 0;
            z-index: 1070;
            width: 100%;
            background-color: #495057;
        }
    }    

}
@media (max-width:880px) {
    .gg-header {
        .top-bar {
            padding-top:10px;
            padding-bottom:10px;
            box-shadow: 3px 3px 6px rgba(0,0,0,.1);
            .top-bar-items2 {
                order: 3;
                .navbar-toggler {
                    display: block;
                }
                .phonenumber {
                    display: none;
                }
            }
            .navbar-brand {
                order:2;
                padding: 0 !important;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
            .top-bar-items {
                order: 1;
                display: flex;
                align-items: center;
                .toggle-basket-preview-label {
                    display: none;
                }
                .control-user a span {
                    display: none !important;
                }
                .controls-list i {
                    font-size: 1.5rem;
                }
                .control-search {
                    .nav-link {
                        position: static;
                    }
                }
            }
            
        }
        .navbar {
            display: none;
        }
    }
}
@media (max-width:400px) {
    .gg-header {
        .top-bar {
            .navbar-brand {
                left: 40%;
            }
        }
    }
}
