.category-home {
    h4, .h4{
        color: $gold_dark;
        font-weight: bold;
        font-family: 'Lato', sans-serif;
        font-size: 1.5rem;
    }

    .owl-theme .owl-dots {
        position: static;
    }

    .gifts-n-sale {
        @media (min-width:650px) {
            margin: 0 60px;
        }
        @media (min-width:950px) {
            display:grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: 7rem auto auto;
        }
        background: $beige_light;
        img {
            max-width: 100%;
        }
        .headline {
            padding-top: 1px;
            h2 {
                text-align: center;
                font-weight: bolder;
                color: $gold_dark;
                margin:2.5rem
            }
        }
        .gifts {
            grid-column-start: 1;
            a {
                padding: 20px 70px;
                @media (max-width:650px) {
                    padding: 20px;  
                }
                display: flex;
                height: 100%;
                align-items: center;
                transition: transform .3s;
                .image { 
                    flex: 0 0 50%;
                    margin-right:20px;
                    img {
                        width: 100%;
                    }
                }
                span {
                    display: block;
                    flex: 1;
                    font-size: 3vw;
                    @media (max-width:950px) {
                        font-size: 6vw; 
                    }
                    @media (min-width: 1400px) {
                        font-size: 3rem;
                    }
                    font-weight: bolder;
                    text-align: center;
                    color: black;
                    span.gold {
                        color: $gold_dark;
                    }
                }
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
        .sale {
            grid-column-start: 2;
            grid-row-start: 1;
            grid-row-end: span 3
        }
    }
}