.cmp-product-thumb {
    .thumb-title {
        color: $gold_dark;
    }
    .price {
        font-size: 1rem;
        font-weight: normal;
    }
    .bottom-bar {
        display: flex;
        justify-content: space-between;
    }
    .actions {
        display: flex;
        justify-content: flex-end;
        .btn-add-to-wishlist {
            z-index: 1;
            position: relative;
            padding: 7px 9px !important;
            line-height: 1em;
            background: black;
            margin-right: 8px;
            i {
                color:white;
            }
            > span {
                display: none;
            }
        }
        .btn-group {
            margin-top: 0;
            .btn-primary {
                padding: 7px;
                flex: 0;
                i.d-none {
                    display: block !important;
                }
            }
        }
        
    }
    @media (max-width:575.98px) {
        .thumb-image {
            padding-bottom: 30px;
            .owl-dots {
                bottom: -30px;
                .owl-dot span {
                    margin: 5px;
                }
            }
        }
    }
}