.widget-background {
    align-items: center !important;
    h1 {
        text-align: center;
        font-weight: 900;
        color: $gold_dark;
    }
    .category-description {
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
    }
    // with image
    &.mh-4 {
        h1 {
            color: white;
        }
    }
    // no image
    &:not(.mh-4) {
        min-height: 250px;
    }
}