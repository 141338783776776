@import 'vars';
@import "widgets/_background.scss";
@import "widgets/_breadcrumbs.scss";
@import "widgets/_filter-price.scss";
@import "widgets/_ggheader.scss";
@import "widgets/_image-carousel.scss";
@import "widgets/_item-image.scss";
@import "widgets/_item-list.scss";
@import "widgets/_naviagation-tree.scss";
@import "widgets/_pagination.scss";
@import "elements/_category_item.scss";
@import "pages/_home.scss";
@import 'thomas';

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

body {
    background: #fff;
    font-family: 'Lato', sans-serif;
}

.container-max {
    max-width: 1400px;
}


.btn-sm, .btn-group-sm > .btn {
    border-radius: unset;
}

#page-body {
    padding-top: 20px;
}

.footer {
    max-width: 1372px;
    h4, .h4 {
        color: $gold_dark;
        font-size: 1.2rem;
        margin-bottom: 30px;
    }
    a {
        color: white !important;
    }
    color: white;
    padding-top: 30px;
    background-color: $grey_dark;
}

@media only screen and (min-width: 1224px){
	.hide-desktop {
		display: none;
	}
}

@media only screen and (max-width: 1224px){
	.hide-mobile {
		display: none;
	}
}
