.widget-pagination {
    ul.pagination {
        li {
            a.page-link {
                border: 0px none;
            }
            &.active {
                a.page-link {
                    font-weight: 900;
                    border: 0px none !important;
                    background-color: unset !important;
                    color: $gold_dark !important;
                }
            }
        }
    }
}