.widget-navigation-tree {
    
    .category-title {
        display: none;
    }
    // hide home button
    .widget-inner > ul:nth-child(2) {
        display: none;
    }
    li.nav-item {
        a.nav-link {
            color: $gold_dark !important;
            .filter-badge {
                background: unset;
                &::before {
                    content:'(';
                }
                &::after {
                    content:')';
                }
            }
        }
        &.active {
            > a.nav-link {
                background: unset;
                font-weight: bold;
            }
        }
    }
    
}